<template>
  <div>
    <NavBar></NavBar>
    <b-container>
      <h4 class="mt-2">Ask a Coupon  (teacher & student)</h4>
      <hr>
      <p>To ask for a coupon please fill this form. I will get back to you by email as soon as possible :)</p>
      <p>Please use an email address from an education institution (example : john.doe@<strong>mit.edu</strong>).</p>
      <p>If you do not have such an address, please explain me your situation in the message section.</p>
      <ContactForm :context="couponContext"></ContactForm>

    </b-container>
    <Footer></Footer>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar";
import ContactForm from "@/components/ContactForm";
import Consts from "@/consts"
export default {
name: "AskCoupon",
  components: {ContactForm, NavBar},
  data() {
    return {
      couponContext : Consts.CONTACT_FORM_CONTEXT.COUPON
    }
  },
  created() {
    window.scrollTo(0,0);
  },
}
</script>

<style scoped>

</style>